import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

// Core Components
import NavbarDefault from "components/navbars/NavbarDefault";
import Footer5 from "components/footers/Footer5.js";
// Demo Sections for this page
import Buttons from "components/index-page/Buttons.js";
import Inputs from "components/index-page/Inputs.js";
import Selects from "components/index-page/Selects.js";
import Dropdowns from "components/index-page/Dropdowns.js";
import Textarea from "components/index-page/Textarea.js";
import Tags from "components/index-page/Tags.js";
import Spinners from "components/index-page/Spinners.js";
import CRTS from "components/index-page/CRTS.js";
import Menus from "components/index-page/Menus.js";
import Navbars from "components/index-page/Navbars.js";
import NavigationPills from "components/index-page/NavigationPills.js";
import ProgressBars from "components/index-page/ProgressBars.js";
import Paginations from "components/index-page/Paginations.js";
import Badges from "components/index-page/Badges.js";
import Alerts from "components/index-page/Alerts.js";
import Toasts from "components/index-page/Toasts.js";
import PreFooters from "components/index-page/PreFooters.js";
import Footers from "components/index-page/Footers.js";
import Typography from "components/index-page/Typography.js";
import Images from "components/index-page/Images.js";
import Tables from "components/index-page/Tables.js";
import Comments from "components/index-page/Comments.js";
import Chat from "components/index-page/Chat.js";
import Cards from "components/index-page/Cards.js";
import Modals from "components/index-page/Modals.js";
import DatePickers from "components/index-page/DatePickers.js";
import TooltipsPopovers from "components/index-page/TooltipsPopovers.js";
import FileUpload from "components/index-page/FileUpload.js";
import Carousel from "components/index-page/Carousel.js";
import NucleoIcons from "components/index-page/NucleoIcons.js";

class SlitLamp extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  static defaultProps = {
    
  }

  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  cleanup = function() {
    // remove style
    document.body.classList.remove("index-page");
  }.bind(this);

  componentDidMount() {
    // add style
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  componentWillUnmount() {
    this.cleanup();
  }

  render() {

    const { history } = this.props;

    return (
      <>
        <NavbarDefault />
        <header className="header-4 skew-separator">
          <div className="header-wrapper">
            <div className="page-header header-video">
              <div className="overlay"></div>
              <video
                autoPlay="autoPlay"
                loop="loop"
                muted="muted"
                playsInline="playsInline"
              >
                <source
                  src={require("assets/videos/WelsAppcam.mp4")}
                  type="video/mp4"
                ></source>
              </video>
              <Container className="text-center">
                <Row>
                  <Col className="mx-auto" lg="7">
                    <h1 className="video-text">Coming Soon</h1>
                    <h1 className="display-3 text-white">
                      The time is right now!
                    </h1>
                    <Button
                      className="btn-icon mt-3 mb-sm-0"
                      color="warning"
                      disabled="true"
                      onClick={function(e) {
                        // e.preventDefault();
                        // history.push("/slitlamp/dashboard");
                        // window.open('http://slitlamp.welsmeditech.co.kr:3000/', '_blank');
                        window.open('http://210.222.208.67:3000/', '_blank');
                      }.bind(this)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-button-play"></i>
                      </span>
                      <span className="btn-inner--text text-capitalize">Get Started</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </header>
        <div className="section features-6">
          <Container>
            <Row>
              <Col className="text-center mx-auto" md="8">
                <h3 className="display-3">Portable Slit Lamp</h3>
                <p className="lead">
                  Cataract diagnosis device using direct lens illumination combined with 
                  mobile and slit lamp. Diagnosis is possible in medically vulerable areas 
                  by mounting the slit lamp on the mobile device.
                </p>
              </Col>
            </Row>
            <br></br><br></br>
            <Row className="align-items-center">
              <Col lg="6">
                <div className="info info-horizontal info-hover-primary">
                  <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                    <i className="ni ni-bulb-61 text-info"></i>
                  </div>
                  <div className="description pl-4">
                    <h5 className="title">Ophthalmic Diagnostic Device</h5>
                    <p>
                      The essential step to reduce loss rate of 
                      eyesight is the accurate diagnosis.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal info-hover-primary">
                  <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                    <i className="ni ni-satisfied text-info"></i>
                  </div>
                  <div className="description pl-4">
                    <h5 className="title">Easy to Carry</h5>
                    <p>
                      Wherever and whoever, take an eye photo 
                      and get consult from eye doctor.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal info-hover-primary">
                  <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                    <i className="ni ni-trophy text-info"></i>
                  </div>
                  <div className="description pl-4">
                    <h5 className="title">Designed user-friendly</h5>
                    <p>
                      Operated simply and specially optimized 
                      concept suits for remote medical diagnosis.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mx-md-auto" lg="6" xs="10">
                <img
                  alt="..."
                  className="ml-lg-5"
                  src={require("assets/img/wels/product2.png")}
                  width="100%"
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer5 />
      </>
    )
  }
}

export default withCookies(SlitLamp);