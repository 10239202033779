import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components
class Team extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <>
        <div className="team-3">
          <Container>
            <Row >
              <Col className="mx-auto text-center mb-5" md="8">
                <h3 className="display-3">Meet our awesome team</h3>
                <p className="lead">
                  People are so scared to lose that they don't even try. Like, one
                  thing people can't say is that I'm not trying, and I'm not
                  trying my hardest, and I'm not trying to do the best way I know
                  how.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6">
                <Card className="card-profile" data-image="profile-image">
                  <CardHeader>
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/wels/kyongwon.jpg")}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h4 className="display-4 mb-0">KyongWon Hwang</h4>
                    <p className="lead">CEO</p>
                    <div className="table-responsive">
                      <ul className="list-unstyled">
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge className="badge-circle mr-3" color="info">
                                <i className="ni ni-atom"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">Welsmeditech representative</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-user-run"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">FDA, CE approval, Commercialization</h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card className="card-profile" data-image="profile-image">
                  <CardHeader>
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/wels/jingu.jpg")}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h4 className="display-4 mb-0">JinGu Lee</h4>
                    <p className="lead">CTO</p>
                    <div className="table-responsive">
                      <ul className="list-unstyled">
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge className="badge-circle mr-3" color="info">
                                <i className="ni ni-atom"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">Head of product development</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-user-run"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">20 years of circuit design and PCB development</h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card className="card-profile" data-image="profile-image">
                  <CardHeader>
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/wels/dami.jpg")}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h4 className="display-4 mb-0">Dami Choi</h4>
                    <p className="lead">CRO</p>
                    <div className="table-responsive">
                      <ul className="list-unstyled">
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge className="badge-circle mr-3" color="info">
                                <i className="ni ni-atom"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">Clinical Research</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-user-run"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">Pre-clinical Research, <br />R&D</h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" md="6">
                <Card className="card-profile" data-image="profile-image">
                  <CardHeader>
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/wels/sungbo.jpg")}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <h4 className="display-4 mb-0">Sungbo Shim</h4>
                    <p className="lead">Head Researcher</p>
                    <div className="table-responsive">
                      <ul className="list-unstyled">
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge className="badge-circle mr-3" color="info">
                                <i className="ni ni-atom"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">Head of electric circuit</h6>
                            </div>
                          </div>
                        </li>
                        <li className="py-1">
                          <div className="d-flex align-items-center">
                            <div>
                              <Badge
                                className="badge-circle mr-3"
                                color="success"
                              >
                                <i className="ni ni-user-run"></i>
                              </Badge>
                            </div>
                            <div>
                              <h6 className="mb-1">12 years of firmware development</h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default Team;
