import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

class NavbarDefault extends React.Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
      collapseOpen: false,
    }
  }

  toggleCollapseOpen = function(isOpen) {
    this.setState({ collapseOpen: isOpen });
  }.bind(this);

  render () {

    const { collapseOpen } = this.state;

    return (
      <>
        <Navbar className="bg-white" fixed="top" expand="lg">
          <Container>
            <NavbarBrand to="/index" tag={Link} id="navbar-brand">
              Welsmeditech
            </NavbarBrand>
            <button
              className="navbar-toggler"
              onClick={function() {
                this.toggleCollapseOpen(!collapseOpen);
              }.bind(this)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Collapse isOpen={collapseOpen} navbar>
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/index">
                      Welsmeditech
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      onClick={function() {
                        this.toggleCollapseOpen(!collapseOpen);
                      }.bind(this)}
                    >
                      <span></span>
                      <span></span>
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-lg-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/index" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/slitlamp" tag={Link}>
                    SlitLamp
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/orthonic" tag={Link}>
                    Orthonic
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" to="/welme" tag={Link}>
                    WelMe
                  </NavLink>
                </NavItem>
                {/* 
                <NavItem>
                  <NavLink className="nav-link-icon" to="/help" tag={Link}>
                    Help
                  </NavLink>
                </NavItem>
                */}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default NavbarDefault;
