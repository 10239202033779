import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

// Core Components
import NavbarDefault from "components/navbars/NavbarDefault";
import Footer5 from "components/footers/Footer5.js";
// Demo Sections for this page
import Buttons from "components/index-page/Buttons.js";
import Inputs from "components/index-page/Inputs.js";
import Selects from "components/index-page/Selects.js";
import Dropdowns from "components/index-page/Dropdowns.js";
import Textarea from "components/index-page/Textarea.js";
import Tags from "components/index-page/Tags.js";
import Spinners from "components/index-page/Spinners.js";
import CRTS from "components/index-page/CRTS.js";
import Menus from "components/index-page/Menus.js";
import Navbars from "components/index-page/Navbars.js";
import NavigationPills from "components/index-page/NavigationPills.js";
import ProgressBars from "components/index-page/ProgressBars.js";
import Paginations from "components/index-page/Paginations.js";
import Badges from "components/index-page/Badges.js";
import Alerts from "components/index-page/Alerts.js";
import Toasts from "components/index-page/Toasts.js";
import PreFooters from "components/index-page/PreFooters.js";
import Footers from "components/index-page/Footers.js";
import Typography from "components/index-page/Typography.js";
import Images from "components/index-page/Images.js";
import Tables from "components/index-page/Tables.js";
import Comments from "components/index-page/Comments.js";
import Chat from "components/index-page/Chat.js";
import Cards from "components/index-page/Cards.js";
import Modals from "components/index-page/Modals.js";
import DatePickers from "components/index-page/DatePickers.js";
import TooltipsPopovers from "components/index-page/TooltipsPopovers.js";
import FileUpload from "components/index-page/FileUpload.js";
import Carousel from "components/index-page/Carousel.js";
import NucleoIcons from "components/index-page/NucleoIcons.js";

class Help extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  static defaultProps = {
    
  }

  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  cleanup = function() {
    // remove style
    document.body.classList.remove("index-page");
  }.bind(this);

  componentDidMount() {
    // add style
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this.cleanup();
  }

  render() {
    return (
      <>
        <NavbarDefault />
        <header className="header-1">
          <div className="page-header">
            <div
              className="page-header-image"
              style={{
                backgroundImage: "url(" + require("assets/img/ill/p2.svg") + ")",
              }}
            ></div>
            <Container>
              <Row>
                <Col
                  className="mr-auto text-left d-flex justify-content-center flex-column"
                  lg="5"
                  md="7"
                >
                  <h3 className="display-3">Argon Design System</h3>
                  <p className="lead mt-0">
                    The time is now for it to be okay to be great. People in this
                    world shun people for being great. For being a bright color.
                  </p>
                  <br></br>
                  <div className="buttons">
                    <Button
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Got it
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </header>
        <Footer5 />
      </>
    )
  }
}

export default withCookies(Help);