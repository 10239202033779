import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

// Core Components
import NavbarDefault from "components/navbars/NavbarDefault";
import Footer5 from "components/footers/Footer5.js";
import Sidebar from "components/sidebar/Sidebar.js";
// Demo Sections for this page
import Buttons from "components/index-page/Buttons.js";
import Inputs from "components/index-page/Inputs.js";
import Selects from "components/index-page/Selects.js";
import Dropdowns from "components/index-page/Dropdowns.js";
import Textarea from "components/index-page/Textarea.js";
import Tags from "components/index-page/Tags.js";
import Spinners from "components/index-page/Spinners.js";
import CRTS from "components/index-page/CRTS.js";
import Menus from "components/index-page/Menus.js";
import Navbars from "components/index-page/Navbars.js";
import NavigationPills from "components/index-page/NavigationPills.js";
import ProgressBars from "components/index-page/ProgressBars.js";
import Paginations from "components/index-page/Paginations.js";
import Badges from "components/index-page/Badges.js";
import Alerts from "components/index-page/Alerts.js";
import Toasts from "components/index-page/Toasts.js";
import PreFooters from "components/index-page/PreFooters.js";
import Footers from "components/index-page/Footers.js";
import Typography from "components/index-page/Typography.js";
import Images from "components/index-page/Images.js";
import Tables from "components/index-page/Tables.js";
import Comments from "components/index-page/Comments.js";
import Chat from "components/index-page/Chat.js";
import Cards from "components/index-page/Cards.js";
import Modals from "components/index-page/Modals.js";
import DatePickers from "components/index-page/DatePickers.js";
import TooltipsPopovers from "components/index-page/TooltipsPopovers.js";
import FileUpload from "components/index-page/FileUpload.js";
import Carousel from "components/index-page/Carousel.js";
import NucleoIcons from "components/index-page/NucleoIcons.js";
import { getConfigFileParsingDiagnostics } from "typescript";
import routes from "views/routes.js";

class Help extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  static defaultProps = {
    
  }

  constructor(props) {
    super(props);

    this.state = {
      sidenavOpen: true,
    }

    this.mainContentRef = React.createRef();
  }

  setSidenavOpen = function(isOpen) {
    this.setState({ sidenavOpen: isOpen });
  }.bind(this);

  // toggles collapse between mini sidenav and normal
  toggleSidenav = function(e) {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setSidenavOpen(!this.state.sidenavOpen);
  }.bind(this);

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContentRef.current.scrollTop = 0;
    }
  }

  componentWillUnmount() {
  
  }

  render() {

    const { sidenavOpen } = this.state;
    const { location } = this.props;

    const getBrandText = function(path) {
      for (let i = 0; i < routes.length; i++) {
        if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
      return "Brand";
    };

    return (
      <>
        <Sidebar
          location={location}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={sidenavOpen}
        />
        <div className="main-content" ref={this.mainContentRef}>
          <Footer5 />
        </div>
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    )
  }
}

export default withCookies(Help);