/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Help from "views/Help";

const routes = [
  {
    path: "/clients",
    name: "Clients",
    icon: "ni ni-single-02 text-primary",
    component: Help,
    layout: "/admin"

  }
];

export default routes;