import React from "react";

// reactstrap components
import { Button, NavItem, Container, Row, Col } from "reactstrap";

// Core Components

function Footer5() {
  return (
    <>
      <footer className="footer footer-big">
        <Container>
          <div className="copyright d-flex flex-row-reverse">
            Copyright © {new Date().getFullYear()}, Designed by Creative Tim. 
            Coded by Welsmeditech.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer5;
