/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";

class Sidebar extends React.Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
    // function used to make sidenav mini or normal
    toggleSidenav: PropTypes.func,
    // prop to know if the sidenav is mini or normal
    sidenavOpen: PropTypes.bool,
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    location: {},
    routes: [{}],
    toggleSidenav: () => {},
    sidenavOpen: false,
  }

  constructor(props) {
    super(props);

    const { routes } = props;
    this.state = this.getCollapseStates(routes) || {};
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  getCollapseStates = function(routes) {
    let initialState = {};
    routes.map(function(prop, key) {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    }.bind(this));
    return initialState;
  }.bind(this);
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState = function(routes) {
    const { location } = this.props;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }.bind(this);

  componentDidMount() {

  }

  componentDidUpdate(prevProps, PrevState) {

  }

  componentWillUnmount() {

  }

  render() {

    const { location, routes, toggleSidenav, sidenavOpen } = this.props;

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
      return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    // makes the sidenav normal on hover (actually when mouse enters on it)
    const onMouseEnterSidenav = () => {
      if (!document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.add("g-sidenav-show");
      }
    };

    // makes the sidenav mini on hover (actually when mouse leaves from it)
    const onMouseLeaveSidenav = () => {
      if (!document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-show");
      }
    };
    
    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    const closeSidenav = () => {
      if (window.innerWidth < 1200) {
        toggleSidenav();
      }
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {
      return routes.map((prop, key) => {
        if (prop.redirect) {
          return null;
        }
        if (prop.collapse) {
          var st = {};
          st[prop["state"]] = !this.state[prop.state];
          return (
            <NavItem key={key}>
              <NavLink
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={this.state[prop.state]}
                className={classnames({
                  active: this.getCollapseInitialState(prop.views),
                })}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(st);
                }}
              >
                {prop.icon ? (
                  <>
                    <i className={prop.icon} />
                    <span className="nav-link-text">{prop.name}</span>
                  </>
                ) : prop.miniName ? (
                  <>
                    <span className="sidenav-mini-icon"> {prop.miniName} </span>
                    <span className="sidenav-normal"> {prop.name} </span>
                  </>
                ) : null}
              </NavLink>
              <Collapse isOpen={this.state[prop.state]}>
                <Nav className="nav-sm flex-column">
                  {createLinks(prop.views)}
                </Nav>
              </Collapse>
            </NavItem>
          );
        }
        return (
          <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink
              to={prop.layout + prop.path}
              activeClassName=""
              onClick={closeSidenav}
              tag={NavLinkRRD}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName !== undefined ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : (
                prop.name
              )}
            </NavLink>
          </NavItem>
        );
      });
    };

    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {/* <NavbarBrand to="/auth" tag={Link}> */}
          <NavbarBrand>
            <span
              style={{
                color: "red",
              }}
            >
              Welsmeditech
            </span>
          </NavbarBrand>
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: sidenavOpen,
              })}
              onClick={toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>{createLinks(routes)}</Nav>
          </Collapse>
        </div>
      </div>
    );
    return (
      <Navbar
        className={
          "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"
        }
        onMouseEnter={onMouseEnterSidenav}
        onMouseLeave={onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

export default Sidebar;