import React from "react";
import ProductCard1 from "components/cards/ProductCard1.js";
import ProductCard2 from "components/cards/ProductCard2.js";
import ProductCard3 from "components/cards/ProductCard3.js";
import ProductCard4 from "components/cards/ProductCard4.js";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components
class Projects extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <>
        <section className="blogs-1">
          <Container>
            <Row>
              <Col className="mx-auto text-center mb-5" md="8">
                <h3 className="display-3">Our recent activities</h3>
                <p className="lead">
                  The time is now for it to be okay to be great. People in this
                  world shun people for being great.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h5 className="desc">Press Release.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <Badge color="info">NEW</Badge>
                        <a 
                          href="https://www.donga.com/news/It/article/all/20230125/117569398/1"
                          target='_blank'>
                          <img alt="..." src={require("assets/img/wels/dong-a_2023.jpg")}></img>
                        </a>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">The DONG-A ILBO</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2023</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h5 className="desc">Consumer Technology Association 2023.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <Badge color="info">NEW</Badge>
                        <img alt="..." src={require("assets/img/wels/ces_2023_1.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">CES 2023_1</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2023</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <Badge color="info">NEW</Badge>
                        <img alt="..." src={require("assets/img/wels/ces_2023_2.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">CES 2023_2</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2023</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <Badge color="info">NEW</Badge>
                        <img alt="..." src={require("assets/img/wels/ces_2023_3.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">CES 2023_3</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2023</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <Badge color="info">NEW</Badge>
                        <img alt="..." src={require("assets/img/wels/ces_2023_4.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">CES 2023_4</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2023</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h5 className="desc">Greater New York Dental Meeting 2022.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/gnydm_2022_1.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">GNYDM 2022_1</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/gnydm_2022_2.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">GNYDM 2022_2</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/gnydm_2022_3.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">GNYDM 2022_3</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h5 className="desc">Awards.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/selection_3.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">Intellectual property</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/selection_2.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">Promising company in Export</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/selection_1.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">The 1000 Innovative Firms</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h5 className="desc">Interviews.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="iframe-container">
                        <iframe
                          width="282.22"
                          height="191.67"
                          title="myIframe"
                          allowFullScreen=""
                          frameBorder="0"
                          src="https://www.youtube.com/embed/x8873BzQVrc"
                        ></iframe>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">R&D Center</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2022</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <h5 className="desc">Other activities.</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/activity2.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">Field test in Cambodia.</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2019</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/activity1.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">Presentation in the Ygnite conference.</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2020</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/activity3.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">MOU with SynCore Biotechnology.</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2020</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-product card-plain">
                      <div className="card-image">
                        <img alt="..." src={require("assets/img/wels/activity4.jpg")}></img>
                      </div>
                      <CardBody>
                        <CardTitle tag="h5">Meeting with Prime Minister.</CardTitle>
                        <CardFooter className="d-flex justify-content-between">
                          <div className="price-container">
                            <span className="price opacity-8">2021</span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }
}

export default Projects;
