import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components
class ContactUs extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <>
        <div
          className="contactus-1 bg-default"
          style={{
            backgroundImage: "url(" + require("assets/img/ill/1.svg") + ")",
          }}
        >
          <Container>
            <br /><br />
            <Row>  
              <Col
                className="d-flex justify-content-center flex-column"
                lg="5"
                md="10"
              >
                <h2 className="title text-white">Cheonan Head Office</h2>
                <h4 className="description text-white">
                  You need more information? Check what other persons are saying
                  about our product. They are very happy with their purchase.
                </h4>
                <div className="info info-horizontal">
                  <div className="icon icon-shape icon-shape-primary shadow rounded-circle text-white">
                    <i className="ni ni-square-pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title text-white">
                      Find us at the office
                    </h4>
                    <p className="description ml-3 text-white">
                      503ho, Multimedia Center, 136, Jiksan-ro, <br></br>
                      Jiksan-eup, Seobuk-gu, Cheonan-si, <br></br>
                      Chungcheongnam-do, Republic of Korea
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-shape icon-shape-primary shadow rounded-circle text-white">
                    <i className="ni ni-mobile-button"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title text-white">Give us a ring</h4>
                    <p className="description ml-3 text-white">
                      ARam Ham <br></br>
                      +82 41 564 5531 <br></br>
                      master@welsmeditech.com <br></br>
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center flex-column"
                lg="5"
                md="10"
              >
                <h2 className="title text-white">Seoul Branch Office</h2>
                <h4 className="description text-white">
                  You need more information? Check what other persons are saying
                  about our product. They are very happy with their purchase.
                </h4>
                <div className="info info-horizontal">
                  <div className="icon icon-shape icon-shape-primary shadow rounded-circle text-white">
                    <i className="ni ni-square-pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title text-white">
                      Find us at the office
                    </h4>
                    <p className="description ml-3 text-white">
                      606ho, 23, Kyungheedae-ro, <br></br>
                      Dongdaemun-gu, Seoul, <br></br>
                      Republic of Korea
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-shape icon-shape-primary shadow rounded-circle text-white">
                    <i className="ni ni-mobile-button"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title text-white">Give us a ring</h4>
                    <p className="description ml-3 text-white">
                      Jimmy Sung <br></br>
                      marketing01@welsmeditech.com <br></br>
                      <br />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <br /><br />
          </Container>
        </div>
      </>
    )
  }
}

export default ContactUs;
