import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components
class Header extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <>
        <header className="header-2 skew-separator">
          <div className="page-header">
            <div
              className="page-header-image"
              style={{
                backgroundImage: "url(" + require("assets/img/wels/bg52.jpg") + ")",
              }}
            ></div>
            <Container className="pt-300">
              <Row>
                <Col className="mx-auto text-center" md="8">
                  <h2 className="display-2">Digital Transformation of Dentistry</h2>
                </Col>
              </Row>
              <Row>
                <div className="floating-box bg-default">
                  <div className="box text-center">
                    <div className="icon icon-shape bg-success icon-xl rounded-circle text-white">
                      <i className="ni ni-spaceship"></i>
                    </div>
                  </div>
                  <h2 className="lead text-white p-5">
                    Transformation of orthodontic care and services into
                    a digital environment. Combining IoT, cloud computing, 
                    sensors, big data and artificial intelligence with 
                    orthodontic treatment and services.
                  </h2>
                </div>
              </Row>
            </Container>
          </div>
        </header>
      </>
    )
  }
}

export default Header;
