import React from "react";
import { createPortal } from "react-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { ClassificationType } from "typescript";

class MyModal extends React.Component {

  static propTypes = {

  }

  // header 3 carousel states and functions
  constructor(props) {
    super(props);

    this.state = {

    }
  }
 
  onMaskClick = function(e) {
    if (e.target === e.currentTarget) {
      this.props.onClose(e);
    }
  }.bind(this);

  // VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie'); 
  // VISITED_NOW_DATE = Math.floor(new Date().getDate());

  render() {
    const { 
      className, 
      onClose, 
      maskCloseAble, 
      closeAble, 
      visible 
    } = this.props;

    return createPortal(
      <Card
        className="card-pricing bg-gradient-warning text-center"
        data-icon="big-icon"
        style={{
          zIndex: '99',
          position: 'relative',
          width: '60%',
          top: '100px',
          left: '10px',
          display: 'inline-block',
        }}
      >
        <CardHeader className="bg-transparent">
          <div className="icon text-white">
            <i className="ni ni-world"></i>
          </div>
        </CardHeader>
        <CardBody>
          <ul className="list-unstyled align-items-center">
            <li>
              <a className="text-sm text-white"
                href='http://www.kmedexpo.com/' 
                target='_blank'>
                WELSMEDITECH participates in K Med Expo Vietnam.<br /> 2023.6.15(Thu) ~ 17(Sat).
              </a>
            </li>
          </ul>
        </CardBody>
        <div className="card-image">
          
          <a href='http://www.kmedexpo.com/' target='_blank'>
            <img
              width="90%"
              alt="..."
              className="img rounded"
              src={require("assets/img/wels/i1_1.png")}
            ></img>
          </a>
        </div>
        <br />
        <br />
        <CardFooter className="bg-transparent">
          <Button 
            outline color="white" 
            type="button"
            onClick={this.props.onClose}>
            CLOSE
          </Button>
        </CardFooter>
      </Card>,
      document.getElementById("modal")
    );
  }
}

export default MyModal;

/*
<a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              width="90%"
              alt="..."
              className="img rounded"
              src={require("assets/img/wels/i1_1.png")}
            ></img>
          </a>
*/