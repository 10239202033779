import React from "react";

// reactstrap components
import {
  Button,
  ButtonGroup,
  UncontrolledCollapse,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import PropTypes, { instanceOf } from "prop-types";

// Core Components
import NavbarDefault from "components/navbars/NavbarDefault.js";
import Header from "components/headers/Header.js";
import Projects from "components/projects/Projects.js";
import Team from "components/teams/Team.js";
import ContactUs from "components/contact-us/ContactUs.js";
import Footer5 from "components/footers/Footer5.js";
import MyModal from "components/modals/MyModal.js";
// Demo Sections for this page
import Buttons from "components/index-page/Buttons.js";
import Inputs from "components/index-page/Inputs.js";
import Selects from "components/index-page/Selects.js";
import Dropdowns from "components/index-page/Dropdowns.js";
import Textarea from "components/index-page/Textarea.js";
import Tags from "components/index-page/Tags.js";
import Spinners from "components/index-page/Spinners.js";
import CRTS from "components/index-page/CRTS.js";
import Menus from "components/index-page/Menus.js";
import Navbars from "components/index-page/Navbars.js";
import NavigationPills from "components/index-page/NavigationPills.js";
import ProgressBars from "components/index-page/ProgressBars.js";
import Paginations from "components/index-page/Paginations.js";
import Badges from "components/index-page/Badges.js";
import Alerts from "components/index-page/Alerts.js";
import Toasts from "components/index-page/Toasts.js";
import PreFooters from "components/index-page/PreFooters.js";
import Footers from "components/index-page/Footers.js";
import Typography from "components/index-page/Typography.js";
import Images from "components/index-page/Images.js";
import Tables from "components/index-page/Tables.js";
import Comments from "components/index-page/Comments.js";
import Chat from "components/index-page/Chat.js";
import Cards from "components/index-page/Cards.js";
import Modals from "components/index-page/Modals.js";
import DatePickers from "components/index-page/DatePickers.js";
import TooltipsPopovers from "components/index-page/TooltipsPopovers.js";
import FileUpload from "components/index-page/FileUpload.js";
import Carousel from "components/index-page/Carousel.js";
import NucleoIcons from "components/index-page/NucleoIcons.js";


class Index extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
    
  }

  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    }
  }

  cleanup = function() {
    // remove style
    document.body.classList.remove("index-page");
  }.bind(this);

  setModalShow = function(value) {
    this.setState({ modalShow: value });
  }.bind(this);

  handleModalClose = function() {
    this.setModalShow(false);
  }.bind(this);

  handleModalShow = function() {
    this.setModalShow(true);
  }.bind(this);

  componentDidMount() {
    // add style
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }

    this.setModalShow(true);
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this.cleanup();
  }

  render() {
    return (
      <>
        {/* <div id="modal" style={{position: 'absolute',}}></div> */}
        <div id="modal" style={{position: 'absolute',}}></div>
        <NavbarDefault />
        {this.state.modalShow && (
          <MyModal 
            visible={this.state.modalShow} 
            closeAble={true} 
            maskCloseAble={true} 
            onClose={this.handleModalClose}>
          </MyModal>
        )}
        <Header />
        <br /><br /><br /><br /><br />
        <Projects />
        <br /><br /><br /><br /><br />
        <Team />
        <br /><br /><br /><br /><br />
        <ContactUs />
        <Footer5 />
      </>
    )
  }
}

export default Index;