import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

// styles
import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-design-system.scss?v1.0.0";

import Index from "views/Index.js";
import SlitLamp from "views/SlitLamp.js";
import Orthonic from "views/Orthonic.js";
import WelMe from "views/WelMe.js";
import Help from "views/Help.js";
import AdminLayout from "views/Admin.js";

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/index" exact render={function(props) {
            return <Index {...props} />
          }} 
        />
        <Route path="/slitlamp" exact render={function(props) {
            return <SlitLamp {...props} />
          }} 
        />
        <Route path="/slitlamp/dashboard" exact render={function(props) {
            return <AdminLayout {...props} />
          }} 
        />
        <Route path="/orthonic" exact render={function(props) {
            return <Orthonic {...props} />
          }} 
        />
        <Route path="/welme" exact render={function(props) {
            return <WelMe {...props} />
          }} 
        />
        {/* 
        <Route path="/help" exact render={function(props) {
            return <Help {...props} />
          }} 
        />
        */}
        <Redirect from="*" to="/index" />
      </Switch>
    </BrowserRouter>,
  </CookiesProvider>,
  document.getElementById("root")
);
